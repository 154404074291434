'use client';

import { useEffect, useState } from 'react';
import Script from 'next/script';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useTranslations, useLocale } from 'next-intl';
import { useRouter } from '@/i18n/routing';
import { createClient } from '@/supabase/client';
import { Button } from '@/components/ui/button';
import { SvgGoogle } from '@/components/auth/SvgGoogle';

const generateNonce = async (): Promise<string[]> => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const nonce = btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(32))));
	const encoder = new TextEncoder();
	const encodedNonce = encoder.encode(nonce);
	const hashBuffer = await crypto.subtle.digest('SHA-256', encodedNonce);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashedNonce = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

	return [nonce, hashedNonce];
};

function OneTapComponent() {
	const router = useRouter();
	const locale = useLocale();
	const t = useTranslations('Login');
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.onGoogleLibraryLoad = async () => {
			const supabase = createClient();
			const [nonce, hashedNonce] = await generateNonce();

			const { data, error } = await supabase.auth.getSession();
			setIsLoading(false);
			if (error) {
				console.error('Error getting session', error);
			}
			if (data.session) {
				setEmail(data?.session?.user?.email);
				setIsLoading(false);
				return;
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.google.accounts.id.initialize({
				client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
				callback: async (response: any) => {
					try {
						// send id token returned in response.credential to supabase
						const { error } = await supabase.auth.signInWithIdToken({
							provider: 'google',
							token: response.credential,
							nonce,
						});

						if (error) throw error;
						router.push('/app');
					} catch (error) {
						console.error('Error logging in with Google One Tap', error);
					}
				},
				nonce: hashedNonce,
				use_fedcm_for_prompt: true,
			});
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// window.google.accounts.id.prompt();
			google.accounts.id.renderButton(document.getElementById('oneTap'), {
				size: 'medium',
				width: 368, // Set the button to full width
				theme: 'outline',
				text: 'continue_with',
				logo_alignment: 'center',
				locale,
			});
		};
	}, [locale, router]);

	return (
		<>
			<Script src="https://accounts.google.com/gsi/client" />
			{/* Light mode style for One Tap */}

			{isLoading && <Spinner size="md" />}
			{email ? (
				<Button
					type="button"
					width="full"
					display="flex"
					alignItems="center"
					justifyContent="center"
					gap={2}
					paddingY={{ base: 2, md: 3 }}
					fontSize={{ base: 'sm', md: 'sm' }}
					onClick={() => router.push('/app')}
				>
					<SvgGoogle />
					<Text
						truncate
						maxWidth="calc(100% - 40px)" // Adjust this to fit your layout
						textAlign="center"
					>
						{t('googleIsLogged')} <b>{email}</b>
					</Text>
				</Button>
			) : (
				<Box margin={2} width="full">
					<div id="oneTap" />
				</Box>
			)}
		</>
	);
}

export default OneTapComponent;
