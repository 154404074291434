export function SvgApple() {
	return (
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.7976 10.607C15.8248 13.5445 18.3748 14.5222 18.4029 14.5347C18.3814 14.6034 17.9956 15.9278 17.0597 17.2954C16.2506 18.4779 15.4108 19.6561 14.0881 19.6808C12.7884 19.7045 12.3704 18.9098 10.8844 18.9098C9.39873 18.9098 8.93451 19.6561 7.70404 19.7045C6.42717 19.7529 5.45482 18.4256 4.63904 17.2476C2.97217 14.8376 1.69826 10.4375 3.40873 7.46731C4.25842 5.99231 5.77717 5.05841 7.42545 5.03435C8.6792 5.01044 9.86263 5.87778 10.629 5.87778C11.395 5.87778 12.8331 4.83466 14.345 4.98778C14.9779 5.01419 16.7545 5.24341 17.8953 6.91341C17.8036 6.97044 15.7756 8.15106 15.7976 10.607ZM13.355 3.39356C14.0328 2.5731 14.489 1.4306 14.3647 0.294189C13.3875 0.333408 12.2059 0.945283 11.5051 1.76544C10.877 2.49153 10.327 3.65403 10.4753 4.76794C11.5645 4.85231 12.677 4.2145 13.355 3.39356Z"
				fill="black"
			/>
		</svg>
	);
}
