export function SvgGoogle() {
	return (
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.6711 8.36794H17.9999V8.33335H10.4999V11.6667H15.2095C14.5224 13.6071 12.6761 15 10.4999 15C7.73865 15 5.4999 12.7613 5.4999 10C5.4999 7.23877 7.73865 5.00002 10.4999 5.00002C11.7745 5.00002 12.9341 5.48085 13.817 6.26627L16.1741 3.90919C14.6857 2.5221 12.6949 1.66669 10.4999 1.66669C5.89781 1.66669 2.16656 5.39794 2.16656 10C2.16656 14.6021 5.89781 18.3334 10.4999 18.3334C15.102 18.3334 18.8332 14.6021 18.8332 10C18.8332 9.44127 18.7757 8.89585 18.6711 8.36794Z"
				fill="#FFC107"
			/>
			<path
				d="M3.12769 6.12127L5.8656 8.12919C6.60644 6.29502 8.4006 5.00002 10.5002 5.00002C11.7748 5.00002 12.9344 5.48085 13.8173 6.26627L16.1744 3.90919C14.686 2.5221 12.6952 1.66669 10.5002 1.66669C7.29935 1.66669 4.52352 3.47377 3.12769 6.12127Z"
				fill="#FF3D00"
			/>
			<path
				d="M10.5 18.3332C12.6525 18.3332 14.6084 17.5094 16.0871 16.1699L13.508 13.9874C12.6432 14.645 11.5865 15.0007 10.5 14.9999C8.33255 14.9999 6.49213 13.6178 5.7988 11.689L3.0813 13.7828C4.46047 16.4815 7.2613 18.3332 10.5 18.3332Z"
				fill="#4CAF50"
			/>
			<path
				d="M18.6712 8.3679H17.9999V8.33331H10.4999V11.6666H15.2095C14.8809 12.5902 14.2888 13.3971 13.5066 13.9879L13.5079 13.9871L16.087 16.1696C15.9045 16.3354 18.8333 14.1666 18.8333 9.99998C18.8333 9.44123 18.7758 8.89581 18.6712 8.3679Z"
				fill="#1976D2"
			/>
		</svg>
	);
}
