import { useTranslations } from 'next-intl';
import { loginWithApple } from '@/app/actions';
import { errorToast } from '@/utils/toast';
import { Button } from '@/components/ui/button';
import { SvgApple } from '@/components/auth/SvgApple';

function AppleLogin() {
	const t = useTranslations('Login');

	return (
		<Button
			type="button"
			onClick={async () => {
				const res = await loginWithApple();
				if (res?.error) {
					errorToast(t('error'), t('errorMessage'));
				}
			}}
			width="full"
			fontWeight={600}
		>
			<SvgApple />
			{t('apple')}
		</Button>
	);
}

export default AppleLogin;
